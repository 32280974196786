<template>
  <div>
    <Wrapper class="w-full max-w-xl">
      <div v-if="me" class="mb-16 text-left mr-auto">
        <h3 class="mb-3 text-xl font-bold text-primary">
          1. Descargue la plantilla
        </h3>
        <p class="text-left text-gray-500">
          Haciendo click en el siguiente botón encontrará la plantilla para el
          cargue masivo de quejas:
        </p>
        <a
          v-if="templateLink"
          :href="templateLink"
          class="mt-5 py-2 max-w-xs flex items-center justify-center gap-3 bg-secondary text-white text-center font-bold rounded-lg hover:text-white hover:bg-gray-400"
          download="plantilla_cargue_masivo"
        >
          <a-icon
            type="cloud-download"
            class="text-lg flex items-center justify-center"
          /><span>Descargar</span>
        </a>
      </div>
      <div class="text-left mr-auto">
        <h3 class="mb-3 text-xl font-bold text-primary">
          2. Cargue la plantilla
        </h3>
        <p class="text-left text-gray-500">
          Adjunte el archivo correspondiente para el cargue masivo de quejas:
        </p>
        <DragDropExcel
          label="En caso de que quiera aportar soportes de los hechos mencionados, adjúntelos aquí:"
          buttonLabel="Seleccione sus archivos"
          id="complaintFiles"
          :fileList="files"
          @files-handler="setFiles"
        />
        <Button
          class="mt-5 max-w-xs"
          :block="true"
          @click.native="uploadComplaints"
        >
          <a-icon
            type="cloud-upload"
            class="text-lg flex items-center justify-center"
          />Cargar quejas
        </Button>
      </div>
    </Wrapper>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data() {
    return {
      templateLink: '',
      files: [],
    }
  },
  computed: {
    ...mapState('session', ['me']),
  },
  watch: {
    me: {
      handler(value) {
        if (!value) return
        this.$getFirebaseLink(value.supports.template_massive).then((url) => {
          this.templateLink = url
        })
      },
      immediate: true,
    },
  },
  methods: {
    setFiles(files) {
      this.files = files
    },
    async uploadComplaints() {
      if (this.files.length === 0) return
      const response = await this.$api.complaints.uploadComplaintsMassive(
        this.files
      )
      if (response.status !== 200) {
        this.$showNotification('error', 'Ocurrio un error al cargar las quejas')
        return
      }
      this.files = []
      this.$showNotification(
        'success',
        'Se procesaron correctamente las quejas'
      )
    },
  },
}
</script>

<style></style>
